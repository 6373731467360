import React, { useEffect, useState } from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import CardContainer from 'common-ui-components/CardContainer';
import { Heading3 } from 'common-ui-components/Typograhpy';
import OrganizationBadge from 'es-src/components/OrganizationBadge';
import AtomSearchResult from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult';
import RelevantPeople from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/RelevantPeople';
import SearchResultsSection from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/SearchResultsSection';
import SearchResultCard from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard';
import Content from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/Content';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import useCouldNotFindAnyResults from 'es-src/screens/HomeScreen/hooks/CouldNotFindAnyResultsHook';
import useFoundAnswerOnly from 'es-src/screens/HomeScreen/hooks/FoundAnswerOnlyHook';
import { TagWithCategories } from 'global/api/controller/EnterpriseSearchController';
import Api from 'global/api/platformApi';
import Organization from 'model/Organization';
import {
  useCustomAtomsFilters,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomAtomsFilters/CustomAtomsFiltersContextProvider';
import {
  toApp,
  toDirectionality,
} from 'screens/platform/cross-platform-components/context/CustomFilters/CustomFiltersUtils';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import ErrorMessage from 'screens/platform/cross-platform-components/ErrorMessage';
import InteractionsPagination from 'screens/platform/cross-platform-components/InteractionsPagination';
import Filters from 'screens/platform/directory/components/ProfileTabs/components/Filters';
import InteractionsCount from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount';
import useDefaultFilters from 'screens/platform/directory/components/ProfileTabs/hooks/DefaultFiltersHook';

import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/icon/magnifying-glass-icon.svg';

import style from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/style.module.scss';
import searchResultCardStyle
  from 'es-src/screens/HomeScreen/components/ResultSection/SearchResultCard/style.module.scss';

const ITEMS_PER_PAGE = 10;

const RegularSearchResults = () => {
  const {
    searchResultsResponse, originalQuestion, data, loading, hasError,
  } = useEnterpriseSearchContext();
  const { getTagColors } = useMetadataContext();
  const { tenant } = useTenantContext();
  const { customAtomsFilters } = useCustomAtomsFilters();
  const [hasUsedFilters, setHasUsedFilters] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleResetFilters } = useDefaultFilters();

  useEffect(() => {
    // We only update all atoms count before we've used any filters.
    // Because that's the time when all atoms are present.
    if (!hasUsedFilters) {
      setCurrentPage(1);
    }
  }, [searchResultsResponse.value]);

  useEffect(() => {
    const filterAtoms = async () => {
      if (!searchResultsResponse.value) return;

      setFiltersLoading(true);
      const response = await Api.EnterpriseSearch.enterpriseSearch(
        tenant.id,
        originalQuestion,
        'userInput',
        undefined,
        false,
        true,
        customAtomsFilters.datesRange
          ? {
            startDate: customAtomsFilters.datesRange.from.getTime(),
            endDate: customAtomsFilters.datesRange.to.getTime(),
          }
          : undefined,
        customAtomsFilters.topics,
        customAtomsFilters.apps?.map(toApp),
        customAtomsFilters.people,
        customAtomsFilters.organizations,
        toDirectionality(customAtomsFilters.directionality),
      );

      if (!response?.data) {
        return;
      }

      setCurrentPage(1);
      setHasUsedFilters(true);
      setFiltersLoading(false);
      searchResultsResponse.set(response?.data.searchResults);
    };

    filterAtoms();
  }, [customAtomsFilters]);

  useEffect(() => {
    setHasUsedFilters(false);
    handleResetFilters();
  }, [originalQuestion]);

  const hasAnswerOnly = useFoundAnswerOnly();
  const couldNotFindAnyResults = useCouldNotFindAnyResults();

  if (loading || (hasError === null && data === null) || couldNotFindAnyResults) {
    return null;
  }

  if (data && data.intent === 'SEARCH' && hasAnswerOnly) {
    return (
      <CardContainer className={searchResultCardStyle.searchResultCard}>
        <Content
          hasError
          icon={MagnifyingGlassIcon}
          title="Search Results"
        >
          <ErrorMessage emoji="👆" heading="Couldn't find any relevant results" subtitle="But have a look at the answer above" />
        </Content>
      </CardContainer>
    );
  }

  if (hasError === 'network') {
    return null;
  }

  const {
    atoms, organizations, topics,
  } = searchResultsResponse?.value || {};

  const collapsed = data != null && (data.intent === 'QUESTION' || data.intent === 'TASK');

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedAtoms = atoms?.results.slice(startIndex, startIndex + ITEMS_PER_PAGE) || [];
  const filteredAtomsCount = atoms?.results.length || 0;

  return (
    <SearchResultCard
      icon={MagnifyingGlassIcon}
      title="Search Results"
      collapsed={collapsed}
      collapsedText="Show Search Results ↓"
    >
      <div className={style.resultsSections}>
        <RelevantPeople itemsPerPage={ITEMS_PER_PAGE} />

        {organizations?.results && (
        <SearchResultsSection<Organization>
          title="Organizations"
          data={organizations.results}
          renderBadge={(organization) =>
            <OrganizationBadge organizationId={organization.id} openNewTab />}
        />
        )}

        {topics?.results && (
        <SearchResultsSection<TagWithCategories>
          title="Topics"
          data={topics.results}
          renderBadge={({ tag }) =>
            <TagBadge tag={tag} colors={getTagColors(tag)} clickable openNewTab />}
        />
        )}

        <div className={style.sectionContainer}>
          <Heading3>Interactions</Heading3>
          <Filters />
          <InteractionsCount
            filteredAtomsCount={filteredAtomsCount}
            loading={filtersLoading}
          />
          {(atoms && filteredAtomsCount > 0) ? (
            <div className={style.sectionContainer}>
              <div>
                {paginatedAtoms.map((atom) => <AtomSearchResult key={atom.id} atom={atom} />)}
              </div>
              <InteractionsPagination
                atomsCount={filteredAtomsCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )
            : (
              <ErrorMessage
                heading="We couldn't find any results that match your filtering criteria."
                subtitle="Please change your filters and try again."
              />
            )}
        </div>
      </div>
    </SearchResultCard>
  );
};

export default RegularSearchResults;
