import React from 'react';
import { useAsync } from 'react-use';

import SpacedBullet from 'common-ui-components/SpacedBullet';
import { Atom } from 'model/Atom';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import useChannelDisplayName from 'screens/platform/cross-platform-components/Hook/ChannelDisplayNameHook';
import { formatDateRelativelyToNow } from 'utils/DateUtils';

import style
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/AtomSearchMetadata/style.module.scss';

interface Props {
  atom: Atom;
}

export default function AtomSearchMetadata({ atom }: Props) {
  const { persons } = useMetadataContext();
  const {
    appId, channelName, isPublic, id, initiatorPersonId, end,
  } = atom;

  const { value: person } = useAsync(
    () => persons.getById(initiatorPersonId ?? ''),
    [initiatorPersonId],
  );

  const channel = useChannelDisplayName(appId, channelName, isPublic, id);
  const createdBy = person?.name ? `Created by ${person.name}` : null;
  const updatedDate = `Updated ${formatDateRelativelyToNow(end)}`;

  return (
    <div className={style.atomSearchMetadata}>
      {channel && (
        <>
          <span className={style.primaryInfo}>
            {channel}
          </span>
          <SpacedBullet />
        </>
      )}
      {createdBy && (
        <>
          <span className={style.secondaryInfo}>
            {createdBy}
          </span>
          <SpacedBullet />
        </>
      )}
      <span className={style.secondaryInfo}>
        {updatedDate}
      </span>
    </div>
  );
}
